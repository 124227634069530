import { Heading } from "theme-ui";

const HelpPanZoom = () => {
  return (
    <div>
      <h1 sx={{ fontSize: [3] }}>Pan and zoom </h1>
      <p>
        Moving around the canvas, zooming in on items or fitting the design into
        your screen.
      </p>

      <Heading>Panning</Heading>
      <p>
        Panning is activated by keybind, mouse button or entering panning mode
        via the panning button
      </p>
      <Heading>Zoom in and out</Heading>
      <p>
        Zoom in and out on the design using the mouse wheel, zoom in button,
        zoom out button.
      </p>
      <Heading>Fit to screen</Heading>
      <p>
        On the bottom of the screen there is a fit to screen button that will
        fit your design to your screen. Helpful when you zoom in to an area to
        work with and want to see the whole design. Or if you get lost and need
        to just see everything.
      </p>
    </div>
  );
};

export default HelpPanZoom;
