const HelpLayers = () => {
  return (
    <div>
      <h1 sx={{ fontSize: [3] }}>Layers </h1>
      <p>
        Your design is organized by layers. This allows you to focus on placing
        items where you want them and create nice layered designs.
      </p>

      <p>
        Common issue comes with how certain layers are locked by default. The
        surface layers like lawn, mulch, concrete all are locked. This is to
        prevent moving these layers by accident which can happen. To disable
        locking go to the layers menu and toggle the lock on the layer.
      </p>

      <ul>
        <li>SVG</li>
        <li>image</li>
        <li>.ecogarden</li>
      </ul>
    </div>
  );
};

export default HelpLayers;
