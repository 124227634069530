import { Heading } from "theme-ui";

const HelpCopyPaste = () => {
  return (
    <div>
      <Heading as="h1" sx={{ marginY: [2, 3] }}>
        Copy and paste
      </Heading>
      <p>
        You can cut, copy, and paste one or more objects. Select the objects you
        want and use the duplicate button or the copy keyboard shortcut.
      </p>

      <Heading>Controls</Heading>
      <Heading as="h3">PC</Heading>
      <ul>
        <li>Ctrl+C - copy</li>
        <li>Ctrl+V - paste</li>
        <li>Ctrl+X - cut</li>
      </ul>
      <Heading as="h3">Mac</Heading>
      <ul>
        <li>⌘c - copy</li>
        <li>⌘v - paste</li>
        <li>⌘x - cut</li>
      </ul>
      <Heading as="h3">Smartphone and tablet</Heading>
      <p>Duplicate button on the selection panel when selecting objects.</p>
    </div>
  );
};

export default HelpCopyPaste;
