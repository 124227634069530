import { HelpCircle } from "@styled-icons/feather/HelpCircle";
import { useEffect, useState } from "react";
import { Link, Route, useLocation, useRoute } from "wouter";
import IconBlock from "../buttons/IconBlock";
import TextLinkButton from "../buttons/TextLinkButton";
import MenuHeader from "../MenuHeader";
import InfoModal from "../modals/InfoModal";
import Help from "./Help";

const HelpContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (!isOpen) {
      setLocation("/");
    }
  }, [isOpen, location, setLocation]);

  return (
    <>
      <TextLinkButton
        onClick={() => {
          setIsOpen(true);
          setLocation("/help");
        }}
      >
        <IconBlock icon={HelpCircle} /> Help
      </TextLinkButton>
      <InfoModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        {...(location !== "/help" && {
          onBackRequest: () => {
            setLocation("/help");
          },
        })}
        title="Help"
      >
        <Help />
      </InfoModal>
    </>
  );
};

export default HelpContainer;
