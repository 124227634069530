const HelpUndoRedo = () => {
  return (
    <div>
      <h1 sx={{ fontSize: [3] }}>Undo and redo</h1>
      <p>We all make mistakes. But don't worry; you can undo it.</p>

      <h2 sx={{ fontSize: [2] }}>Controls</h2>
      <h3 sx={{ fontSize: [2] }}>PC</h3>
      <ul>
        <li>control + z - undo</li>
        <li>control + y - redo</li>
      </ul>

      <h3 sx={{ fontSize: [2] }}>Mac</h3>
      <ul>
        <li>command + z - undo</li>
        <li>command + y - redo</li>
      </ul>
      <h3 sx={{ fontSize: [2] }}>Smartphone & tablet</h3>
      <p>Undo/redo buttons on the side of the screen</p>
    </div>
  );
};

export default HelpUndoRedo;
