/* eslint-disable max-lines-per-function */
import { Link, Route, Switch } from "wouter";
import FAQ from "./FAQ";
import HelpCopyPaste from "./HelpCopyPaste";
import HelpExporting from "./HelpExporting";
import HelpLayers from "./HelpLayers";
import HelpPanZoom from "./HelpPanZoom";
import HelpShapes from "./HelpShapes";
import HelpUndoRedo from "./HelpUndoRedo";
import SelectionControls from "./SelectionControls";

const Help = () => {
  return (
    <div
      sx={{
        paddingX: 3,
        width: "100%",
        pointerEvents: "all",
        userSelect: "text",
        // maxHeight: 'calc(var(--vh, 1vh) * 95)',
        // overflowY: 'auto',
        // overflowX: 'hidden'
      }}
    >
      <Switch>
        <Route path="/help/faq">
          <FAQ />
        </Route>
        <Route path="/help/selection">
          <SelectionControls />
        </Route>
        <Route path="/help/shapes">
          <HelpShapes />
        </Route>
        <Route path="/help/exporting">
          <HelpExporting />
        </Route>
        <Route path="/help/layers">
          <HelpLayers />
        </Route>
        <Route path="/help/pan_zoom">
          <HelpPanZoom />
        </Route>
        <Route path="/help/undo_redo">
          <HelpUndoRedo />
        </Route>
        <Route path="/help/copy_paste">
          <HelpCopyPaste />
        </Route>
        <Route path="/help">
          <p>
            Creating a design can be challenging. Hopefully we can help you with
            how Ecogarden Design works and how to use it.
          </p>
          <div>
            <ul>
              <li>
                <Link href="/help/faq">FAQ</Link>
              </li>
              <li>
                <Link href="/help/selection">Selection and controls</Link>
              </li>
              <li>
                <Link href="/help/shapes">
                  Shapes, objects, polygon, line, freedraw
                </Link>
              </li>
              <li>
                <Link href="/help/exporting">
                  Exporting image, SVG, .ecogarden
                </Link>
              </li>
              <li>
                <Link href="/help/layers">Layers</Link>
              </li>
              <li>
                <Link href="/help/pan_zoom">Panning and zooming</Link>
              </li>
              <li>
                <Link href="/help/undo_redo">Undo and redo</Link>
              </li>
              <li>
                <Link href="/help/copy_paste">Copy, cut, paste, duplicate</Link>
              </li>
            </ul>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Help;
