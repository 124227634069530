import { Layers } from "@styled-icons/feather/Layers";
import { Lock } from "@styled-icons/feather/Lock";
import { Heading } from "theme-ui";
import FitToIcon from "../icons/fit-to.svg";

const FAQ = () => {
  return (
    <div>
      <Heading>Why can&apos;t I select anything?</Heading>
      <p>
        By default some objects in layers are locked to prevent moving items
        accidently. To unlock use the Layers{" "}
        <Layers width={22} height={22} strokeWidth={2} /> menu and toggle the
        lock <Lock width={22} height={22} strokeWidth={2} /> on the layer.
      </p>
      <Heading>When I draw something I why can't see it?</Heading>
      <p>
        If you are using a dark version you may find the default color to be
        black. Black is used by default for printing and exporting purposes.
        Consider how you will be using your design and chose the color
        appropriate for this. Let us know if you feel we should do something
        differently.
      </p>

      <Heading>I did something but where is my design?</Heading>
      <p>
        You can use the fit to screen{" "}
        <FitToIcon
          width={22}
          height={22}
          focusable={false}
          aria-hidden={true}
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            overflow: "hidden",
          }}
        />{" "}
        button that will position your design in the middle and make it fully
        visible.
      </p>
    </div>
  );
};

export default FAQ;
