import { Heading } from "theme-ui";

const SelectionControls = () => {
  return (
    <div>
      <Heading>Selection and controls</Heading>
      <p>Selecting and manipulating your design into your dreams.</p>

      <div>
        <p>
          You can select an item by clicking on it. You can select multiple
          items by clicking/touching and dragging the mouse to make your
          selection.
        </p>
      </div>

      <div>
        <p>
          Once selected there will be controls added to the shapes. This allows
          you to change the size and position of the shapes.
        </p>
      </div>

      <div>
        <p>
          If you make a mistake, you can undo it. Press control + z on PC,
          command + z on Mac. On smartphones and tablets there is an undo button
          to the right of the screen.
        </p>
      </div>

      <div>
        <p>
          Many shapes are limited in their size to help ground the design. If
          you find your shape is not getting any larger or smaller this is
          commonly the effect.
        </p>
      </div>
    </div>
  );
};

export default SelectionControls;
