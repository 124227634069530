import { Heading } from "theme-ui";

const HelpShapes = () => {
  return (
    <div>
      <Heading>Exporting </Heading>
      <p>
        Your design is interactable on a Ecogarden Design canvas but you can
        export it to various different formats for sharing with others and on
        social media.
      </p>

      <ul>
        <li>SVG</li>
        <li>image</li>
        <li>.ecogarden</li>
      </ul>
    </div>
  );
};

export default HelpShapes;
