import { Heading } from "theme-ui";

const HelpExporting = () => {
  return (
    <div>
      <Heading>Shapes and objects</Heading>
      <p>
        We use custom made shapes, like trees and cars, made from various shapes
        as well as some you can create yourself.
      </p>
      <ul>
        <li>custom shapes</li>
        <li>polygon</li>
        <li>line</li>
        <li>freedraw</li>
        <li>circle</li>
        <li>rectangle</li>
        <li>ellipse</li>
      </ul>
    </div>
  );
};

export default HelpExporting;
